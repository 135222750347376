@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    @font-face {
        font-family: "Cooper Hewitt Book";
        font-weight: 700;
        font-style: normal;
        src: url("../public/assets/fonts/CooperHewitt-Book.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Thin";
        font-weight: 300;
        src: url("../public/assets/fonts/CooperHewitt-Thin.otf") ;
    }

    @font-face {
        font-family: "Cooper Hewitt Thin Italic";
        font-weight: 300;
        font-style: italic;
        src: url("../public/assets/fonts/CooperHewitt-ThinItalic.otf");
    }

    @font-face {

        font-family: "Cooper Hewitt Light";
        src: url("../public/assets/fonts/CooperHewitt-Light.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Light Italic";
        src: url("../public/assets/fonts/CooperHewitt-LightItalic.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Book Italic";
        src: url("../public/assets/fonts/CooperHewitt-BookItalic.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Medium";
        font-weight: 700;
        font-style: normal;
        src: url("../public/assets/fonts/CooperHewitt-Medium.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Medium Italic";
        src: url("../public/assets/fonts/CooperHewitt-MediumItalic.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Semibold";
        src: url("../public/assets/fonts/CooperHewitt-Semibold.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Semibold Italic";
        src: url("../public/assets/fonts/CooperHewitt-SemiboldItalic.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Bold";
        font-weight: 700;
        font-style: normal;
        src: url("../public/assets/fonts/CooperHewitt-Bold.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Bold Italic";
        src: url("../public/assets/fonts/CooperHewitt-BoldItalic.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Heavy";
        src: url("../public/assets/fonts/CooperHewitt-Heavy.otf");
    }

    @font-face {
        font-family: "Cooper Hewitt Heavy Italic";
        src: url("../public/assets/fonts/CooperHewitt-HeavyItalic.otf");
    }

    h1 {
        @apply font-cooper-hewitt-bold;
    }

    h2 {
        @apply font-cooper-hewitt-medium;
    }

    h3 {
        @apply font-cooper-hewitt-bold;
    }

    h4 {
        @apply font-cooper-hewitt-semibold;
    }

    p {
        @apply font-cooper-hewitt-book;
    }

    .input-label a {
        text-decoration: underline;
    }

    .button {
        @apply font-cooper-hewitt-semibold uppercase;
    }

    .hover-scale {
        @apply hover:scale-105 transition-transform;
    }

    .ul {
        list-style-type: none;
    }

    * { -webkit-tap-highlight-color: rgba(0,0,0,0); }

    .faq > h1 {
        @apply mt-6 mb-2;
    }
    .faq > h2 {
        @apply my-2;
    }
}






