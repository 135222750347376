.bubble {
	position: absolute;
	left: 0;
	top: 0;
	width: 60px;
	height: 60px;
}

.bubble.bravo-anim {
	animation-name: wave-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 2.5s;          /* Change to speed up or slow down */
	animation-iteration-count: 1;    /* Never stop waving :) */
	transform-origin: 50% 50%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

.bubble.stainding_ovation-anim {
	animation-name: balloon-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 5s;          /* Change to speed up or slow down */
	animation-iteration-count: 1;    /* Never stop waving :) */
	transform-origin: 50% 50%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes wave-animation {
    0% {
		transform: translate3D(0, 0, 0);
		 animation-timing-function: ease-in;
	   }
  100% { transform: translate3D(30px, -300px, 0) }
}

.bubble.applause-anim {
	animation-name: balloon-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 5s;          /* Change to speed up or slow down */
	animation-iteration-count: 1;    /* Never stop waving :) */
	transform-origin: 50% 50%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes applause-animation {
    0% {
		transform: translate3D(0, -60px, 0);
		 animation-timing-function: ease-in;
	}
	90% {
		transform: translate3D(30px, -500px, 0);
		opacity: 1;
	}
	100% {
		transform: translate3D(30px, -520px, 0);
		opacity: 0;
	}
}


.bubble.beer-anim {
	animation-name: beer-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 2.5s;          /* Change to speed up or slow down */
	animation-iteration-count: 1;    /* Never stop waving :) */
	transform-origin: 50% 50%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes beer-animation {
    0% { transform: translate3D(0, 0, 0) rotate(0deg);
		 animation-timing-function: ease-out;
	   }
	50% {
		transform: translate3D(100px, -200px, 0) rotate(160deg);
		animation-timing-function: ease-in;		
	}
	100% {
		transform: translate3D(200px, 20px, 0) rotate(320deg);
	}
}


.bubble.rock-anim {
	animation-name: rock-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 2.5s;          /* Change to speed up or slow down */
	animation-iteration-count: 1;    /* Never stop waving :) */
	transform-origin: 50% 50%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes rock-animation {
    0% { transform: translate3D(0, 0, 0) rotate(0deg);
		 animation-timing-function: ease-out;
	   }
	10% {
		transform: translate3D(0, -60px, 0);
		animation-timing-function: ease-in;
	}
	20% {
		transform: translate3D(0, -50px, 0);
		animation-timing-function: ease-in;
	}
	30% {
		transform: translate3D(0, -62px, 0);
		animation-timing-function: ease;
	}
	40% {
		transform: translate3D(0, -52px, 0);
		animation-timing-function: ease;
	}
	50% {
		transform: translate3D(0, -63px, 0);
		animation-timing-function: ease;
	}
	60% {
		transform: translate3D(0, -55px, 0);
		animation-timing-function: ease;
	}
	70% {
		transform: translate3D(0, -65px, 0);
		animation-timing-function: ease;
	}
	80% {
		transform: translate3D(0, -52px, 0);
		animation-timing-function: ease;
	}
	90% {
		transform: translate3D(0, -60px, 0);
		animation-timing-function: ease;
		opacity: 1;
	}
	100% {
		transform: translate3D(0, 0, 0);
		opacity: 0;
	}
}

.bubble.hands_up-anim {
	animation-name: hands_up-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 2.5s;          /* Change to speed up or slow down */
	animation-iteration-count: 1;    /* Never stop waving :) */
	transform-origin: 30% 100%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes hands_up-animation {
    0% { transform: translate3D(0, 0, 0) rotate(0deg);
		 animation-timing-function: ease-out;
	   }
	10% {
		transform: translate3D(0, -60px, 0) rotate(0deg);
		animation-timing-function: ease-in;
	}
	20% {
		transform: translate3D(0, -50px, 0) rotate(-15deg);
		animation-timing-function: ease-in;
	}
	30% {
		transform: translate3D(0, -62px, 0) rotate(20deg);
		animation-timing-function: ease;
	}
	40% {
		transform: translate3D(0, -52px, 0) rotate(-17deg);
		animation-timing-function: ease;
	}
	50% {
		transform: translate3D(0, -63px, 0) rotate(15deg);
		animation-timing-function: ease;
	}
	60% {
		transform: translate3D(0, -55px, 0) rotate(-15deg);
		animation-timing-function: ease;
	}
	70% {
		transform: translate3D(0, -65px, 0) rotate(20deg);
		animation-timing-function: ease;
	}
	80% {
		transform: translate3D(0, -52px, 0) rotate(-15deg);
		animation-timing-function: ease;
	}
	90% {
		transform: translate3D(0, -60px, 0) rotate(0deg);
		animation-timing-function: ease;
		opacity: 1;
	}
	100% {
		transform: translate3D(0, 0, 0);
		opacity: 0;
	}
}

.bubble.hat-anim {
	animation-name: hat-animation;    /* Refers to the name of your @keyframes element below */
	animation-duration: 2.5s;          /* Change to speed up or slow down */
	transform-origin: 50% 50%;         /* Pivot around the bottom-left palm */
	display: inline-block;
}

@keyframes hat-animation {
    0% { transform: translate3D(0, 0, 0) rotate(0deg);
		 animation-timing-function: ease-out;
	   }
	50% {
		transform: translate3D(30px, -300px, 0) rotate(290deg);
		animation-timing-function: ease-in;		
	}
	100% {
		transform: translate3D(60px, 20px, 0) rotate(580deg);
	}
}
