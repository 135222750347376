.video-js.stage .vjs-control-bar {
    /* top: 20px; */
    left: 23%;
    width: 54%;
    right: 23%;
    background-color: black;
}

/* .vjs_video_58234-dimensions { */
/*     position: absolute; */
/*     left: 0; */
/*     right: 0; */
/*     top: 0; */
/*     bottom: 0; */
/*     width: auto; */
/* } */


