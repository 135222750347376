.mySwiper .swiper-pagination-bullet {
  background-color: white !important;
}

.mySwiper .swiper-pagination-bullet-active {
  background-color: #ac8243 !important;
}

.card-text {
  display: none;
}

.card .card-text {
  display: block !important;
}

.grow {
  transition: all 0.4s 0.15s ease-in-out, z-index 0.55s;
}

/* .grow:hover { */
/*     transform: scale(1.6); */
/*     position: absolute; */
/* } */

.active {
  border-bottom-color: #ac8243;
}

.card-details {
  transition: opacity 0.4s 0.15s ease-in-out;
  font-size: 14px;
}

.card-details-title {
  @apply text-gray-dark mt-2 truncate;
  font-size: 12px;
  font-weight: bold;
}

.card-details-duration {
  @apply text-gray-dark;
  font-size: 8px;
  padding-left: 2px;
  padding-bottom: 8px;
}

.card-details-tag {
  @apply bg-gray-light mr-1 text-gray-dark uppercase rounded;
  font-size: 6px;
  padding: 3px 4px;
}

.card-details-time {
  font-size: 10px;
  padding: 5px 4px;
}

.card-details-tickets {
  @apply font-bold bg-gold tracking-tighter uppercase flex items-center rounded-full text-white;
  font-size: 8px;
  padding: 4px 8px;
}

.detail-img {
  /* transform-origin: 95% 5%; */
  /* transition: transform .4s ease-in-out; */
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.grow:hover .detail-img {
  /* transform: scale(0.24); */
  transition: opacity 0.4s 0.3s ease-in-out;
  opacity: 0;
}

.drop-down-z-index {
  z-index: 9999 !important;
}

.disabled-dropdown {
  pointer-events: none;
}

@media screen and (min-width: 1560px) {
  .more-menu-container {
    display: none;
  }
}

@media screen and (min-width: 1464px) and (max-width: 1559px) {
  .more-menu-container {
    display: block;
  }

  .shop,
  .foyer-more-menu,
  .schedule-more-menu,
  .productions-more-menu,
  .about-theater-more-menu {
    display: none;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1463px) {
  .shop,
  .about-theater,
  .foyer-more-menu,
  .schedule-more-menu,
  .productions-more-menu {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .shop,
  .about-theater,
  .productions,
  .foyer-more-menu,
  .schedule-more-menu {
    display: none;
  }
}

.trailer-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.vjs-big-play-button-img {
  opacity: 1;
  position: absolute;
  width: 3em;
  height: 2em;
  border: 0.06666em solid #fff;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headline-shadow {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}
